import React from "react";

function MapMobile() {
  return (
    <>
      <iframe
        title="mapcds"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.89887145607733!2d-34.87170915772666!3d-8.062466967816416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab193e32946701%3A0x9d4c8176d3f1dfeb!2sStation%20Work!5e0!3m2!1spt-BR!2sbr!4v1700155497575!5m2!1spt-BR!2sbr"
        width="300"
        height="400"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="eager"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
}

export default MapMobile;
