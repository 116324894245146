import { Icon } from "@iconify/react";
import React from "react";

function CopyrightFooter() {
  return (
    <footer className="bg-roxo">
      <div className="h-8 text-white/70 text-xs tracking-tight py-3 w-full max-sm:px-5 flex items-center justify-center uppercase overflow-clip max-[360px]:flex-wrap max-[360px]:h-fit lg:h-10">
        <a href="https://bresults.com.br/">
          <h1 className="whitespace-nowrap flex items-center justify-center max-[360px]:flex-wrap">
            Station Work <Icon icon="ph:copyright" className="mx-1" />
            {/*  copyright */} 2024 |{" "}
            <span className="ml-1">desenvolvido por b results</span>
          </h1>
        </a>
      </div>
    </footer>
  );
}

export default CopyrightFooter;
