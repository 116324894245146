import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import "../index.css";

import Office from '../assets/auditorio.jpg';

function AreaAuditorio() {
  const [showFirstElement, setShowFirstElement] = useState(true);

  const toggleElements = () => {
    setShowFirstElement((val) => !val);
  };

  return (
    <div className='md:p-3 max-w-xl'>
      {showFirstElement ? 
      <div className='flex relative z-0 flex-row MDparalelograma  max-sm:w-full max-sm:h-40 h-48'>
        <div className='flex z-0 max-sm:w-52 max-sm:h-40 max-sm:pr-2'>
          <img className="object-contain" src={Office} alt="Offices"/>
        </div>

        <div className='flex end-0 justify-between h-full w-2/3 absolute z-10 bg-roxo paralelograma2 max-sm:ml-32 max-sm:h-40' >
          <div className='flex justify-center items-center ml-10 w-2/3 max-sm:ml-4 text-center'>
            <h1 className='text-white font-semibold text-2xl/tight max-sm:text-lg/tight text-center'>Auditório Multifuncional</h1>
          </div>

          <div className='flex items-center p-3'>
            <button className='flex justify-center items-center rounded-full bg-white max-sm:w-10 max-sm:h-10' onClick={toggleElements} >
              <Icon className='w-10 h-10 mt-1 mx-0.5' icon="dashicons:plus" color="#322F3A" />
            </button>
          </div>
        </div>
      </div>
      :
      <div className='flex flex-row items-center justify-between MDparalelograma  max-sm:w-full max-md:h-40 h-48 bg-roxo'>
        <div className='flex flex-col justify-center items-center bg-roxo max-sm:w-full max-sm:h-40 md:w-3/4 mx-10' >
          <div className=' max-sm:my-2 max-sm:mr-1 text-center'>
            <h1 className='text-white font-semibold text-2xl max-sm:text-xl text-center mb-2'>Auditório</h1>
            <h2 className='text-white text-lg/none font-medium max-sm:text-base/none'>
              Auditório pré mobiliado com mesas compartilhadas, retroprojetor, ecrã de projeção e capacidade para 20 pessoas sentadas.
              Metragem: 55,26m² ou 70 cadeiras*
              <br />
              <br />
              <small>*Verificar a disponibilidade de cadeiras, durante o agendamento do serviço.</small></h2>
          </div>
        </div>

        <div className='flex items-center p-3'>
            <button className='flex justify-center items-center rounded-full bg-white max-sm:w-10 max-sm:h-10' onClick={toggleElements} >
              <Icon className='max-sm:w-10 max-sm:h-10 w-12 h-12' icon="ic:baseline-minus" color="#322F3A" />
            </button>
        </div>
      </div>
      }
    </div>
  );
}

export default AreaAuditorio;