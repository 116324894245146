import { Icon } from "@iconify/react";
import React, { useState } from "react";
import PopupForm from "./popupForm";

import "./styles/carroselPlanos.css";

import { plans } from "../data/plans";
import { pass } from "../data/plans";

const CarouselPricesDesktop = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 3; // Number of cards to display per page

  const totalPages = Math.ceil(plans.length / cardsPerPage);

  const nextSlide = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages - 1 ? 0 : prevPage + 1
    );
  };

  const prevSlide = () => {
    setCurrentPage((prevPage) =>
      prevPage === 0 ? totalPages - 1 : prevPage - 1
    );
  };

  const renderCards = () => {
    const startIndex = currentPage * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;

    return plans.slice(startIndex, endIndex).map((plan, index) => (
      <div
        key={index}
        className={`carousel__slide px-5 pb-2 pt-2 flex flex-col justify-between items-center shadow-md bg-white mx-5 flex-1 max-lg:my-4 max-w-sm max-lg:mx-auto`}
      >
        <div>
          <img src={plan.logo} alt={plan.title} className="max-w-[240px]" />
        </div>
        <p className="mx-4 text-center text-sm/none mb-1">{plan.description}</p>
        <div className="h-px w-full bg-roxo my-2"></div>
        <h3 className="font-bold text-xl pl-3 text-left w-full">
          {plan.title === "station pass" ? plan.caption : "O que está incluso"}
        </h3>
        <ul
          className={`list-disc marker:text-roxo text-left text-base tracking-tight leading-tight flex flex-col justify-center my-2 pl-5`}
        >
          {plan.features.map((feature, index) => (
            <li
              key={index}
              className={`border border-white py-0.5 px-1 my-0 text-left`}
            >
              {feature}
            </li>
          ))}
        </ul>
        {plan.title === "station pass" ? (
          <h3 className="font-bold text-xl pl-3 text-left w-full">
            {plan.captionRot}
          </h3>
        ) : (
          ""
        )}
        {plan.title === "station pass" ? (
          <ul
            className={`list-disc marker:text-roxo text-left text-base tracking-tight leading-tight flex flex-col justify-center mt-3 mb-5 pl-5`}
          >
            {plan.featuresRot.map((feature, index) => (
              <li
                key={index}
                className={`border border-white py-0.5 px-1 my-0 text-left`}
              >
                {feature}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
        <button
          type="button"
          onClick={() => setShowDialog(true)}
          className={`bg-roxo text-white transition-all ease-in-out my-1 text-3xl hover:text-4xl font-medium py-2 px-4 w-fit cursor-pointer whitespace-nowrap rounded-2xl hover:rounded-none`}
        >
          Tenho Interesse
        </button>
        <div className="text-center text-xs text-roxoEscuro mt-4">
          <p>Plano mensal com renovação automática.</p>
          <p>Ambiente seguro e criptografado.</p>
        </div>
        <div>
          {showDialog && <PopupForm onClose={() => setShowDialog(false)} />}
        </div>
      </div>
    ));
  };

  return (
    <div className="container-carrossel bg-roxo relative py-12 px-8 lg:px-20 xl:px-16">
      <h2 className="font-bold uppercase text-white text-4xl text-center mb-4">
        planos
      </h2>
      <div className="carousel text-roxoEscuro bg-roxo relative mx-5">
        <button
          className={`carousel__arrow carousel__arrow--prev absolute top-60 -left-12 cursor-pointer hidden`}
          onClick={prevSlide}
        >
          <Icon
            icon="bytesize:chevron-left"
            width={60}
            height={60}
            color="white"
          />
        </button>
        {/* aqui foi necessário fazer dessa forma, com o componente fora da função de renderização ao invés de fazer renderização condicional (<button> ou <a> pq senão buga a formatação e os botões dos cards nao ficam alinhados. refatorar quando possível */}
        <div className="flex max-lg:flex-wrap justify-center">
          {renderCards()}
          <div
            className={`carousel__slide px-5 pb-2 pt-2 flex flex-col justify-between items-center shadow-md bg-white mx-5 flex-1 max-lg:my-4 max-w-sm max-lg:mx-auto`}
          >
            <div>
              <img src={pass.logo} alt={pass.title} className="max-w-[240px]" />
            </div>
            <p className="mx-4 text-center text-sm/none mb-1">
              {pass.description}
            </p>
            <div className="h-px w-full bg-roxo my-2"></div>
            <h3 className="font-bold text-xl pl-3 text-left w-full">
              O que está incluso
            </h3>
            <ul
              className={`list-disc marker:text-roxo text-left text-base tracking-tight leading-tight flex flex-col justify-center my-2 pl-5`}
            >
              {pass.features.map((feature, index) => (
                <li
                  key={index}
                  className={`border border-white py-0.5 px-1 my-0 text-left`}
                >
                  {feature}
                </li>
              ))}
            </ul>
            <a
              href="https://www.asaas.com/c/cvv1dai7d8e0tbvp"
              target="_blank"
              rel="noreferrer"
              className={`bg-roxo text-white transition-all ease-in-out my-1 text-3xl hover:text-4xl font-medium py-2 px-4 w-fit cursor-pointer whitespace-nowrap rounded-2xl hover:rounded-none`}
            >
              Tenho Interesse
            </a>
            <div className="text-center text-xs text-roxoEscuro mt-4">
              <p>Plano mensal com renovação automática.</p>
              <p>Ambiente seguro e criptografado.</p>
            </div>
            <div>
              {/* {showDialog && <PopupForm onClose={() => setShowDialog(false)} />} */}
            </div>
          </div>
        </div>
        <button
          className={`carousel__arrow carousel__arrow--next absolute top-60 -right-12 cursor-pointer hidden`}
          onClick={nextSlide}
        >
          <Icon
            icon="bytesize:chevron-right"
            width={60}
            height={60}
            color="white"
          />
        </button>
      </div>
    </div>
  );
};

export default CarouselPricesDesktop;
