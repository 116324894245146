import { Icon } from "@iconify/react";
import React from "react";

function Footer() {
  return (
    <footer className="bg-roxo flex text-white uppercase items-center ">
      <div className="flex-1 text-center pr-3 bg-roxoEscuro py-3">
        <h4 className="text-sm/tight">
          canais de
          <br /> venda
          <br /> <strong>online</strong>
        </h4>
      </div>
      <div className="h-8 w-px bg-white" />
      <div className="flex-1 flex items-center justify-center py-3">
        <a
          className="flex-1 flex items-center justify-center"
          href="tel:+5581982721300"
        >
          <Icon icon="mdi:telephone" color="white" width={28} />
          <h5 className="text-xs/none ml-1">
            por
            <br /> telefone
          </h5>
        </a>
      </div>
      <div className="h-8 w-px bg-white" />
      <div className="flex-1 flex items-center justify-center py-3">
        <a
          className="flex-1 flex items-center justify-center"
          href="https://api.whatsapp.com/send?phone=5581982721300"
        >
          <Icon icon="ri:whatsapp-fill" color="white" width={28} />
          <h5 className="text-xs/none ml-1">
            por
            <br /> whatsapp
          </h5>
        </a>
      </div>
      {/* <div>
      </div> */}
    </footer>
  );
}

export default Footer;
