import { Icon } from "@iconify/react";
import React, { useState } from "react";
import PopupForm from "./popupForm";

import { plansmob } from "../data/plans";
import "./styles/carroselPlanos.css";

const CarouselPrices = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === plansmob.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? plansmob.length - 1 : prevSlide - 1
    );
  };

  const currentPlan = plansmob[currentSlide];

  return (
    <div className="container-carrossel bg-roxo relative py-6">
      <h2 className="font-bold uppercase text-white text-4xl text-center mb-2">
        planos
      </h2>
      <div className="carousel text-roxoEscuro bg-white relative mx-10 shadow-md">
        <button
          className={`carousel__arrow carousel__arrow--prev absolute top-60 -left-12 cursor-pointer`}
          onClick={prevSlide}
        >
          <Icon
            icon="bytesize:chevron-left"
            width={60}
            height={60}
            color="white"
          />
        </button>
        <div
          className={`carousel__slide px-5 pb-3 pt-2 flex flex-col justify-center items-center`}
        >
          <div>
            <img src={currentPlan.logo} alt={currentPlan.title} className="" />
          </div>
          <p className="mx-2 text-center text-base/none mb-2">
            {currentPlan.description}
          </p>

          <div className="h-px w-full bg-roxo my-2"></div>
          <h3 className="font-bold text-xl pl-3 text-left w-full">
            {currentPlan.title === "station pass"
              ? currentPlan.caption
              : "O que está incluso"}
          </h3>
          <ul
            className={`list-disc marker:text-roxo text-left text-base tracking-tight leading-tight flex flex-col justify-center mt-2 mb-2 pl-5`}
          >
            {currentPlan.features.map((feature, index) => (
              <li key={index} className={`my-0 text-left`}>
                {feature}
              </li>
            ))}
          </ul>
          {currentPlan.title === "station pass" ? (
            <h3 className="font-bold text-xl pl-3 text-left w-full">
              {currentPlan.captionRot}
            </h3>
          ) : (
            ""
          )}
          {currentPlan.title === "station pass" ? (
            <ul
              className={`list-disc marker:text-roxo text-left text-base tracking-tight leading-tight flex flex-col justify-center mt-2 mb-2 pl-5`}
            >
              {currentPlan.featuresRot.map((feature, index) => (
                <li key={index} className={`my-0 text-left`}>
                  {feature}
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
          {currentPlan.title === "station postal" ? (
            <a
              href="https://www.asaas.com/c/cvv1dai7d8e0tbvp"
              target="_blank"
              rel="noreferrer"
              className={`bg-roxo text-white transition-all ease-in-out my-1 text-3xl hover:text-4xl font-medium py-2 px-4 w-fit cursor-pointer whitespace-nowrap rounded-2xl hover:rounded-none max-sm:text-2xl`}
            >
              Tenho Interesse
            </a>
          ) : (
            <button
              type="button"
              onClick={() => setShowDialog(true)}
              className={`bg-roxo text-white transition-all ease-in-out my-1 text-3xl hover:text-4xl font-medium py-2 px-4 w-fit cursor-pointer whitespace-nowrap rounded-2xl hover:rounded-none max-sm:text-2xl`}
            >
              Tenho Interesse
            </button>
          )}

          <div className="text-center text-xs text-roxoEscuro mt-5">
            <p>Plano mensal com renovação automática.</p>
            <p>Ambiente seguro e criptografado.</p>
          </div>
        </div>
        <div>
          {showDialog && <PopupForm onClose={() => setShowDialog(false)} />}
        </div>
        <button
          className={`carousel__arrow carousel__arrow--prev absolute top-60 -right-12 cursor-pointer`}
          onClick={nextSlide}
        >
          <Icon
            icon="bytesize:chevron-right"
            width={60}
            height={60}
            color="white"
          />
        </button>
      </div>
    </div>
  );
};

export default CarouselPrices;
