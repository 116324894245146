import stationPassLogo from "../assets/marcasPlanos/Station_Marcas-02.png";
import stationOfficeLogo from "../assets/marcasPlanos/Station_Marcas-04.png";
import stationPostalLogo from "../assets/marcasPlanos/Station_Marcas-03.png";

export const plans = [
  {
    title: "station office",
    logo: stationOfficeLogo,
    description:
      "Tenha um espaço reservado para o seu time em um ambiente que inspira criação e negócios",
    features: [
      "Escritórios privativos e completos, prontos para uso",
      "Adaptados às necessidades da sua equipe",
      "Perfeito para empresas que buscam personalização e privacidade",
      "50% na aquisição de outros serviços",
    ],
  },
  {
    title: "station pass",
    logo: stationPassLogo,
    description:
      "Acesso exclusivo e estações de trabalho para profissionais, empreendedores, freelancers e etc.",
    caption: "Pass fixo",
    captionRot: "Pass rotativo",
    features: [
      "Cadeira no open space reservado",
      "Acesso free a 1h sala de reunião",
      "Acesso à comunidade e instalações compartilhadas",
      "50% na aquisição de outros serviços",
    ],
    featuresRot: [
      "Utilização dos espaços compartilhados para trabalho",
      "Acesso à comunidade e instalações compartilhadas",
      "50% na aquisição de outros serviços",
    ],
  },
];

export const pass = {
  title: "station postal",
  logo: stationPostalLogo,
  description:
    "Sua empresa no endereço mais inovador do Brasil, no parque tecnológico de Recife",
  features: [
    "Endereço comercial e fiscal que posiciona seu negócio",
    "Serviços de recepção e controle de correspondência",
    "Manutenção da imagem profissional",
    "Acesso à sala de reunião e outros espaços com 50% de desconto",
  ],
};

export const plansmob = [
  {
    title: "station office",
    logo: stationOfficeLogo,
    description:
      "Tenha um espaço reservado para o seu time em um ambiente que inspira criação e negócios",
    features: [
      "Escritórios privativos e completos, prontos para uso",
      "Adaptados às necessidades da sua equipe",
      "Perfeito para empresas que buscam personalização e privacidade",
      "50% na aquisição de outros serviços",
    ],
  },
  {
    title: "station pass",
    logo: stationPassLogo,
    description:
      "Acesso exclusivo e estações de trabalho para profissionais, empreendedores, freelancers e etc.",
    caption: "Pass fixo",
    captionRot: "Pass rotativo",
    features: [
      "Cadeira no open space reservado",
      "Acesso free a 1h sala de reunião",
      "Acesso à comunidade e instalações compartilhadas",
      "50% na aquisição de outros serviços",
    ],
    featuresRot: [
      "Utilização dos espaços compartilhados para trabalho",
      "Acesso à comunidade e instalações compartilhadas",
      "50% na aquisição de outros serviços",
    ],
  },
  {
    title: "station postal",
    logo: stationPostalLogo,
    description:
      "Sua empresa no endereço mais inovador do Brasil, no parque tecnológico de Recife",
    features: [
      "Endereço comercial e fiscal que posiciona seu negócio",
      "Serviços de recepção e controle de correspondência",
      "Manutenção da imagem profissional",
      "Acesso à sala de reunião e outros espaços com 50% de desconto",
    ],
  },
];
