import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const BackButton = () => {
  return (
    <Link to="/blog/posts">
      <button
        title="Voltar"
        className="border border-roxo bg-white text-roxo hover:text-white hover:bg-roxo rounded-full p-2 hover:p-3 hover:-translate-y-1 transition-all ease-linear absolute right-10"
      >
        <Icon icon="lets-icons:back" width={20} />
      </button>
    </Link>
  );
};

export default BackButton;
