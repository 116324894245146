import { Icon } from "@iconify/react";

export default function Loader() {
  return (
    <div className="flex flex-col justify-center items-center text-3xl md:5xl font-bold p-40">
      <Icon
        icon="gg:spinner"
        width={60}
        height={60}
        className="mb-5 animate-spin duration-300"
      />
      <p>Carregando...</p>
    </div>
  );
}
