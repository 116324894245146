import { Icon } from "@iconify/react";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const ShareButtons = (data) => {
  const { shareUrl, title } = data;

  return (
    <div className="mx-10 md:mx-32 flex justify-center items-center mt-2 mb-8 text-roxo max-md:flex-wrap">
      <p className="text-lg uppercase font-semibold mr-4 max-md:mb-4">
        compartilhe
      </p>
      <div className="flex justify-center items-center">
        <FacebookShareButton
          url={shareUrl}
          className="mx-2 hover:-translate-y-1 transition-all ease-linear"
        >
          <Icon icon="mdi:facebook" width={30} height={30} />
        </FacebookShareButton>

        <FacebookMessengerShareButton
          url={shareUrl}
          appId="521270401588372"
          className="mx-2 hover:-translate-y-1 transition-all ease-linear"
        >
          <Icon icon="mdi:facebook-messenger" width={30} height={30} />
        </FacebookMessengerShareButton>

        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="mx-2 hover:-translate-y-1 transition-all ease-linear"
        >
          <Icon icon="mdi:telegram" width={30} height={30} />
        </TelegramShareButton>

        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="mx-2 hover:-translate-y-1 transition-all ease-linear"
        >
          <Icon icon="fa6-brands:x-twitter" width={30} height={30} />
        </TwitterShareButton>

        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="mx-2 hover:-translate-y-1 transition-all ease-linear"
        >
          <Icon icon="mdi:whatsapp" width={30} height={30} />
        </WhatsappShareButton>

        <LinkedinShareButton
          url={shareUrl}
          className="mx-2 hover:-translate-y-1 transition-all ease-linear"
        >
          <Icon icon="mdi:linkedin" width={30} height={30} />
        </LinkedinShareButton>

        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="mx-2 hover:-translate-y-1 transition-all ease-linear"
        >
          <Icon icon="mdi:email" width={30} height={30} />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default ShareButtons;
