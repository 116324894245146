import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "../index.css";

import LocalizacaoFoto from "../assets/LocPrivilegiada.jpg";

function Localizacao() {
  const [showFirstElement, setShowFirstElement] = useState(true);

  const toggleElements = () => {
    setShowFirstElement((val) => !val);
  };

  return (
    <div className="relative flex-1">
      {showFirstElement ? (
        <div className="flex bg-black relative z-20 flex-row w-full h-52">
          <div className="w-full h-52 absolute overflow-clip">
            <img
              className="object-contain"
              src={LocalizacaoFoto}
              alt="Imagem decorativa"
            />
          </div>
          <div className="flex absolute z-0 justify-center items-center w-full h-full flex-row">
            <div className="flex bg-roxo paralelograma3 mr-4 w-72 h-14">
              <div className="flex justify-center items-center w-full h-full max-sm:ml-0 max-sm:mr-0 max-sm:my-0 text-center">
                <h1 className="text-white font-semibold max-sm:text-xl text-center">
                  Localização privilegiada
                </h1>
              </div>
            </div>

            <div className="absolute ml-72 max-sm:mt-2">
              <button
                className="flex justify-center items-center rounded-full bg-white max-sm:w-10 max-sm:h-10"
                onClick={toggleElements}
              >
                <Icon
                  className="w-10 h-10 pt-1"
                  icon="dashicons:plus"
                  color="#322F3A"
                />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full h-52 relative">
          <div className="absolute h-52 w-full z-10 bg-roxo/80" />

          <div className="max-sm:w-full max-sm:h-52 overflow-clip">
            <img
              className="object-contain"
              src={LocalizacaoFoto}
              alt="Imagem decorativa"
            />
          </div>

          <div className="flex flex-col z-30 absolute justify-end items-center max-sm:w-full max-sm:h-52">
            <div className="w-3/4 max-sm:mx-1 max-sm:mt-1 mb-3 text-center z-30 mt-10">
              <h2 className="text-white text-base/none z-30">
                Ficamos localizados na Rua Do Bom Jesus, N°125, eleita pela
                ARCHITECTURAL DIGEST A terceira mais bonita do mundo.
                <br />
                Com visão privilegiada do Marco Zero e fácil acesso
              </h2>
            </div>
            <div className="flex z-30 max-sm:w-10 max-sm:h-10 w-10 h-10 mb-5">
              <button
                className="flex z-30 justify-center items-center rounded-full bg-white max-sm:w-10 max-sm:h-10"
                onClick={toggleElements}
              >
                <Icon
                  className="max-sm:w-14 max-sm:h-14 w-10 h-10"
                  icon="ic:baseline-minus"
                  color="#322F3A"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Localizacao;
