import React, { useState } from "react";
import "../index.css";

import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logoStation from "../assets/Logo_Station.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div id="navbar-container" className="flex fixed z-40">
        <div
          className={`slide-container ${
            isOpen ? "slide-down" : "slide-up"
          } w-screen md:hidden`}
        >
          <nav
            id="navigationMobile"
            className="w-screen h-20 bg-white flex max-md:flex-row max-sm:w-screen fixed justify-around items-center pt-8 pb-8 max-md:w-full max-md:top-0 max-md:h-20 shadow-sm"
          >
            <div
              className="flex text-branco px-3 md:hidden max-md:mt-0 max-md:items-center justify-center text-roxo "
              id="menu"
            >
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-12 text-sm/none active:animate-pulse transition-all ease-in-out uppercase"
              >
                {isOpen ? (
                  <Icon
                    icon="teenyicons:left-solid"
                    width={48}
                    height={48}
                    className="max-md:rotate-90 text-roxo"
                  />
                ) : (
                  <Icon
                    className="text-roxo"
                    icon="eva:menu-outline"
                    width={48}
                    height={48}
                  />
                )}
              </button>
            </div>

            <div className="w-36 flex items-center justify-center py-4 max-md:w-48 max-md:ml-8">
              <HashLink smooth="true" to="/#top">
                <img src={logoStation} alt="LogoStation" />
              </HashLink>
            </div>

            <div className="w-full flex items-center justify-center py-4 md:hidden max-md:w-48 max-md:ml-3 ">
              <HashLink smooth="true" to="/#contato">
                <h1 className="text-white leading-none py-1.5 bg-roxo max-md:w-24 flex items-center justify-center text-center paralelograma ">
                  Quero ser <br /> membro
                </h1>
              </HashLink>
            </div>
          </nav>

          <div id="opened-menu" className="bg-white p-5 sm:mt-20 max-md:ml-0">
            <ul className="text-left text-xl font-semibold text-roxo">
              <li className="my-3">
                <HashLink smooth="true" to="/#planos">
                  <h3>Planos</h3>
                </HashLink>
              </li>
              <li className="my-3">
                <HashLink smooth="true" to="/#estrutura">
                  <h3>Estrutura</h3>
                </HashLink>
              </li>
              <li className="my-3">
                <HashLink smooth="true" to="/#local">
                  <h3>Local</h3>
                </HashLink>
              </li>
              <li className="my-3">
                <Link smooth="true" to="/blog/posts">
                  <h3>Blog</h3>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <nav
          id="navigationDesktop"
          className="w-screen h-20 bg-white flex max-md:flex-row max-sm:w-screen fixed justify-around items-center pt-8 pb-8 max-md:w-full max-md:top-0 max-md:h-20 max-md:hidden shadow-sm"
        >
          <div className="w-48 flex items-center justify-center py-4 max-md:w-48 max-md:ml-5 ">
            <HashLink smooth="true" to="/#top">
              <img src={logoStation} alt="LogoStation" />
            </HashLink>
          </div>
          <div
            id="iconesredes"
            className="flex flex-row w-2/5 justify-around items-center max-md:hidden text-xl text-roxo"
          >
            <div className="flex p-1 items-center justify-center w-10 h-10 mx-auto my-3 ">
              <HashLink smooth="true" to="/#planos">
                <h3>Planos</h3>
              </HashLink>
            </div>

            <div className="flex p-1 items-center justify-center w-10 h-10 mx-auto my-3 ">
              <HashLink smooth="true" to="/#estrutura">
                <h3>Estrutura</h3>
              </HashLink>
            </div>

            <div className="flex p-1 items-center justify-center w-10 h-10 mx-auto my-3">
              <HashLink smooth="true" to="/#local">
                <h3>Local</h3>
              </HashLink>
            </div>

            <div className=" flex p-2 items-center justify-center w-10 h-10 mx-auto my-3 ">
              <Link smooth="true" to="/blog/posts">
                <h3>Blog</h3>
              </Link>
            </div>
          </div>

          {
            <div className="w-52  max-md:hidden">
              <HashLink smooth="true" to="/#contato">
                <h1 className="text-white bg-roxo max-md:w-24 py-2 rounded-lg text-xl flex items-center justify-center text-center hover:rounded-none hover:shadow-sm transition-all ease-linear">
                  Quero ser membro
                </h1>
              </HashLink>
            </div>
          }
        </nav>
      </div>
    </>
  );
}

export default Navbar;
