/* eslint-disable max-len */
/* eslint-disable no-alert */
import React, { useState, useRef } from "react";
import RD from "../utils/RD";
// import emailjs from "@emailjs/browser";

function FormContato() {
  // inicializando estado do componente
  const initialState = {
    servico: "Auditório",
    nome: "",
    email: "",
    whatsapp: "",
    quantpessoas: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  // função para controlar os inputs do componente
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Format phone number dynamically as the user types
    if (name === "whatsapp") {
      const formattedValue = formatPhoneNumber(value);
      setFormData({ ...formData, [name]: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const form = useRef();

  const formatPhoneNumber = (input) => {
    const phoneNumber = input.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = "";

    for (let i = 0; i < phoneNumber.length; i++) {
      if (i === 0) {
        formattedValue = `(${phoneNumber[i]}`;
      } else if (i === 2) {
        formattedValue += `) ${phoneNumber[i]}`;
      } else if (i === 7) {
        formattedValue += `-${phoneNumber[i]}`;
      } else {
        formattedValue += phoneNumber[i];
      }
    }

    return formattedValue;
  };

  // função para enviar os dados do formulário
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      RD(formData)
        .then(() => {
          window.alert("Mensagem enviada! Em breve retornaremos o contato.");
          setIsLoading(false);
          setFormData(initialState);
        })
        .catch((error) => {
          window.alert("Algo deu errado! Por favor tente novamente.");
          setIsLoading(false);
          return error;
        });
    } catch (error) {
      console.log(error, "um erro ocorreu");
    }

    return event;
  };

  return (
    <div id="minhadiv" className="p-6 pt-12 bg-roxo">
      <form
        ref={form}
        onSubmit={handleSubmit}
        className="flex flex-col w-full max-[820px]:pt-4"
      >
        <h4 className="text-2xl font-extrabold text-white text-center pb-5 max-[280px]:w-min">
          Tenho interesse no espaço para realizar meu evento
        </h4>
        <label
          htmlFor="nome"
          className="caret-neutral-600 text-neutral-400 text-lg my-1 flex-1"
        >
          <input
            type="text"
            name="nome"
            placeholder="Nome"
            required
            value={formData.nome}
            onChange={handleChange}
            className="focus:outline-none max-h-full w-full p-2 text-neutral-600"
          />
        </label>
        <label
          htmlFor="email"
          className="caret-neutral-600 text-neutral-400 text-lg my-1 flex-1"
        >
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            required
            value={formData.email}
            onChange={handleChange}
            className="focus:outline-none max-h-full w-full p-2 text-neutral-600"
          />
        </label>
        <label
          htmlFor="whatsapp"
          className="caret-neutral-600 text-neutral-400 text-lg my-1 flex-1"
        >
          <input
            type="text"
            name="whatsapp"
            placeholder="Whatsapp (apenas numeros, com DDD)"
            required
            value={formData.whatsapp}
            onChange={handleChange}
            maxLength={15}
            className="focus:outline-none max-h-full w-full p-2 text-neutral-600"
          />
        </label>
        <label
          htmlFor="quantpessoas"
          className="caret-neutral-600 text-neutral-400 text-lg my-1 flex-1"
        >
          <input
            name="quantpessoas"
            placeholder="Quantidade de pessoas no evento"
            required
            value={formData.quantpessoas}
            onChange={handleChange}
            className="focus:outline-none max-h-full w-full p-2 text-neutral-600"
          />
        </label>
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-white rounded-2xl p-4 my-5 text-roxo max-sm:w-2/3 w-1/3 text-xl uppercase font-semibold hover:rounded-none transition-all ease-in"
          >
            {isLoading ? "Enviando..." : "Enviar"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default FormContato;
