import React from "react";

import stationSuccess from "../assets/swsuccess.png";
import BomJesus from "../assets/ruabomjesus.jpg";
import MarcoZero from "../assets/MarcoZeroClip.jpg";
import { HashLink } from "react-router-hash-link";

function Banner() {
  return (
    <div className="flex-1 " alt="carrosel">
      <div
        className="relative h-96 bg-bottom bg-no-repeat bg-cover pt-20 max-sm:mt-20 max-sm:pt-0 max-sm:h-32"
        style={{ backgroundImage: `url(${BomJesus})` }}
      >
        {/*  <img className="w-screen h-60" src={BomJesus} alt="foto da Rua do Bom Jesus" /> */}
      </div>

      <div className="flex justify-center items-center max-md:flex-col max-md:items-center max-md:text-center max-md:py-5 max-md:h-fit bg-roxo h-44 max-md:pb-10">
        <div className="text-white max-md:text-4xl max-sm:text-3xl max-sm:leading-tight max-md:mb-4 max-md:font-semibold text-4xl font-semibold">
          <h2 className="max-md:mb-1 max-md:px-8 text-center pr-8 ">
            Sua empresa <br className="md:hidden" /> no coração do{" "}
            <br className="" /> Porto Digital do <br className="md:hidden" />{" "}
            Recife Antigo
          </h2>
        </div>
        <div className="text-white max-md:w-64 w-3/12 h-16">
          <HashLink smooth to="/#planos">
            <h4 className="bg-white text-roxo p-2 max-md:text-2xl max-md:py-1 max-md:px-1 max-sm:font-semibold max-sm:rounded-xl flex text-3xl rounded-xl font-semibold items-center justify-center text-center">
              Vem para o coworking!
            </h4>
          </HashLink>
        </div>
      </div>

      {/* <div className="max-md:w-full max-md:h-60 h-60 ">
          <img className="w-screen h-60" src={MarcoZero} alt="foto do Marco Zero"/>
          
          <img className="w-screen h-60 max-md:hidden" src={BomJesus} alt="Your Station is success"/>
        </div> */}

      <div
        className="relative h-80 bg-center bg-no-repeat bg-cover max-sm:pt-0 max-sm:h-60 max-md:bg-left"
        style={{ backgroundImage: `url(${MarcoZero})` }}
      >
        {/*  <img className="w-screen h-60" src={BomJesus} alt="foto da Rua do Bom Jesus" /> */}
        <div className="max-md:hidden relative flex justify-end w-full h-full">
          <div className="w-2/5 bg-white h-full flex items-center p-5 md:p-20">
            <img
              src={stationSuccess}
              alt="Imagem com os dizeres Your Station is Success"
              className="object-contain"
            />
          </div>
        </div>
      </div>

      <div className="md:hidden flex justify-center items-center text-center py-2 px-3 bg-white w-screen h-40">
        <div className="bg-white h-full flex items-center px-10 py-10 md:p-20">
          <img
            src={stationSuccess}
            alt="Imagem com os dizeres Your Station is Success"
            className="object-contain"
          />
        </div>
        {/* <h1 className="text-roxo text-4xl max-sm:text-xl font-extrabold">
          YOUR STATION IS SUCCESS
        </h1> */}
      </div>
    </div>
  );
}

export default Banner;
