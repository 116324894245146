import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fetchSinglePost from "../utils/fetchSinglePost";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyrightFooter from "../components/CopyrightFooter";
import Loader from "../components/Loader";
import ShareButtons from "../components/ShareButtons";
import BackButton from "../components/BackButton";

const SinglePost = () => {
  const currUrl = window.location.href;
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    // Fetch single post when the component mounts
    const fetchData = async () => {
      try {
        const data = await fetchSinglePost(id);
        setPost(data);
      } catch (error) {
        console.error(`Error fetching post with ID ${id}:`, error);
      }
    };

    fetchData();
  }, [id]); // Re-run the effect whenever the 'id' changes

  return (
    <>
      <Navbar />
      <section className="mx-10 mt-20 pt-6 md:mt-0 md:mx-32 md:pt-28 relative text-roxo">
        <BackButton />
        {post !== null ? (
          <article className="max-w-5xl mx-auto">
            <h2 className="text-3xl md:text-5xl text-center font-bold">
              {post.title.rendered}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: post.content.rendered.replace(/\n\n\n/g, "<br />"),
              }}
              className="mt-8 mb-14"
            />
            {/* You can customize the display as needed */}
            <ShareButtons shareUrl={currUrl} title={post.title.rendered} />
          </article>
        ) : (
          <Loader />
        )}
      </section>
      <Footer />
      <CopyrightFooter />
    </>
  );
};

export default SinglePost;
