const baseURL = 'https://stationwork.com.br'
// const testURL = 'http://localhost:4000'

function RD(info) {
  // console.log('entramos na funçao', info);
  const {nome, email, whatsapp, quantpessoas, servico } = info;
  const data = {
    deal: {
      name: nome
    },
    deal_products: [
      {
        name: servico,
        amount: quantpessoas,
      }
    ],
    contacts: [
      {
        phones: [
          {
            type: "cellphone",
            phone: whatsapp,
          }
        ],
        name: nome,
        emails: [
          {
            email: email,
          }
        ]
      }
    ],
  };

  // Make the fetch request
  const response = fetch(`${baseURL}/createDeal/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(response => response.json())
  .then(data => {
    console.log('Deal created:', data);
    return 'deal created!';
    // window.alert('contato enviado')
    // Handle the response from the server
  })
  .catch(error => {
    console.error('Error:', error);
    throw new Error('there was an error');
    // window.alert('Houve um erro, por favor tente novamente!')
    // Handle errors
  });
  return response
}

export default RD;

