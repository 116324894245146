import React from "react";

import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import AreaOffice from "../components/AreaOffice";
import AreaReuniao from "../components/AreaReuniao";
import AreaHall from "../components/AreaHall";
import AreaAuditorio from "../components/AreaAuditorio";
import Comunidade from "../components/Comunidade";
import Endereco from "../components/Endereco";
import Localizacao from "../components/Localizacao";
// import Paceiros from "../components/Carrossel/Paceiros";
import FormContato from "../components/FormContato";
import FormContatoEventos from "../components/FormContatoEventos";
import CarouselPrices from "../components/CarrosselPrecos";
import CarouselPricesDesktop from "../components/CarrosselPrecosDesktop";
import Footer from "../components/Footer";
import CarrosselParceiros from "../components/Carrossel/Paceiros/Paceiros";
import CarrosselComentarios from "../components/Carrossel/Comentarios/Comentarios";
import CarrosselComentariosDesktop from "../components/Carrossel/Comentarios/ComentariosDesktop";
import CopyrightFooter from "../components/CopyrightFooter";
import Maps from "../components/Maps/Maps";

function Home() {
  return (
    <>
      <div id="top">
        <Navbar />

        <Banner />
        <div
          id="estrutura"
          className="md:flex md:flex-wrap md:p-10 md:px-28 max-w-[1380px] mx-auto"
        >
          <div className="md:flex-1">
            <AreaOffice />
            <AreaReuniao />
          </div>
          <div className="md:flex-1">
            <AreaHall />
            <AreaAuditorio />
          </div>
        </div>
        <div id="local" className="md:flex">
          <Comunidade />
          <Endereco />
          <Localizacao />
        </div>
        <div id="Maps" className="flex">
          <Maps />
        </div>
        <div id="parceiros">
          <CarrosselParceiros />
        </div>
        <div id="comentarios">
          <div className="md:hidden">
            <CarrosselComentarios />
          </div>
          <div className="max-md:hidden">
            <CarrosselComentariosDesktop />
          </div>
        </div>
        <div id="planos">
          <div className="md:hidden">
            <CarouselPrices />
          </div>
          <div className="max-md:hidden">
            <CarouselPricesDesktop />
          </div>
        </div>

        <div id="contato" className="md:flex">
          <div className="md:flex-1 md:px-16 bg-black">
            <FormContato />
          </div>
          <div className="md:flex-1 md:px-16 bg-roxo">
            <FormContatoEventos />
          </div>
        </div>

        <Footer />
        <CopyrightFooter />
      </div>
    </>
  );
}

export default Home;
