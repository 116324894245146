import { useEffect } from "react";

function Camarote() {
  useEffect(() => {
    // Redirect to an external link when the component is loaded
    window.location.href =
      "https://www.sympla.com.br/evento/lounge-station-2024/2313159?referrer=l.instagram.com&share_id=copiarlink";
  }, []);

  return <></>;
}

export default Camarote;
