import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from './pages/Home';
import BlogHome from './pages/BlogHome';
import SinglePost from './pages/SinglePost';
import Camarote from './pages/Camarote';
import ErrorPage from './pages/ErrorPage';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/blog/posts",
    element: <BlogHome />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/blog/posts/:id",
    element: <SinglePost />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/camarote",
    element: <Camarote />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

