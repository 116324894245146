import React from 'react';
import Carrossel from './Carrossel';
import CarrosselDesktop from './CarrosselDesktop';
/* import foto from '../../../assets/Office.PNG'; */

function Parceiros() {

  return (
      <div className='flex flex-col mx-5 md:mx-12 mt-6'>
        <div className='flex flex-col md:flex-row justify-center items-center p-5'>
          <h1 className='text-3xl md:text-5xl font-extrabold max-sm:pt-4 max-sm:pb-4 text-roxo md:text-right md:w-2/3'>Empresas que estão<br /> na Station</h1>
          <p className='text-xl md:text-2xl/none md:text-left font-medium md:px-8 text-roxoEscuro md:w-2/3'>Vibrantes e Potentes, conheça algumas das companhias marcantes da nossa comunidade.</p>
        </div>

        <div className='md:hidden py-5'>
          <Carrossel />
        </div>
        <div className='max-md:hidden py-10 px-5'>
          <CarrosselDesktop />
        </div>
        

      </div>
    
  );
}

export default Parceiros;