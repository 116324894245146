import React from "react";
import BlogPosts from "../components/BlogPosts";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyrightFooter from "../components/CopyrightFooter";

const BlogHome = () => {
  return (
    <>
      <Navbar />
      <BlogPosts />
      <Footer />
      <CopyrightFooter />
    </>
  );
};

export default BlogHome;
