import React, { useState } from "react";
import RD from "../utils/RD";

function PopupForm({ onClose }) {
  const initialState = {
    servico: "Office",
    nome: "",
    email: "",
    whatsapp: "",
    quantpessoas: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false); // Track form validity

  const handleClose = (event) => {
    event.preventDefault();
    setFormData(initialState);
    setIsOpen(false);
    onClose();
  };

  // função para controlar os inputs do componente
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Format phone number dynamically as the user types
    if (name === "whatsapp") {
      const formattedValue = formatPhoneNumber(value);
      setFormData({ ...formData, [name]: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setIsFormValid(formIsValid());
  };

  // Function to validate form
  const formIsValid = () => {
    return (
      formData.nome.trim() !== "" &&
      formData.email.trim() !== "" &&
      formData.whatsapp.trim() !== "" &&
      formData.quantpessoas.trim() !== "" &&
      formData.servico.trim() !== ""
    );
  };

  const formatPhoneNumber = (input) => {
    const phoneNumber = input.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedValue = "";

    for (let i = 0; i < phoneNumber.length; i++) {
      if (i === 0) {
        formattedValue = `(${phoneNumber[i]}`;
      } else if (i === 2) {
        formattedValue += `) ${phoneNumber[i]}`;
      } else if (i === 7) {
        formattedValue += `-${phoneNumber[i]}`;
      } else {
        formattedValue += phoneNumber[i];
      }
    }

    return formattedValue;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      RD(formData)
        .then(() => {
          window.alert("Mensagem enviada! Em breve retornaremos o contato.");
          setIsLoading(false);
          setFormData(initialState);
          onClose();
        })
        .catch((error) => {
          window.alert("Algo deu errado! Por favor tente novamente.");
          setIsLoading(false);
          return error;
        });
    } catch (error) {
      console.log(error, "um erro ocorreu");
    }
  };

  return (
    <dialog open={isOpen} className="rounded-lg shadow-lg absolute top-40">
      <div className="p-12 bg-black text-white flex flex-col justify-center items-center text-2xl uppercase font-semibold">
        <form
          method="dialog"
          onSubmit={handleSubmit}
          className="flex flex-col w-full max-[820px]:pt-4"
        >
          <h4 className="text-2xl font-extrabold text-white text-center pb-5 max-[280px]:w-min">
            Deixe suas informações que entraremos em contato!
          </h4>
          <label
            htmlFor="nome"
            className="caret-neutral-600 text-neutral-400 text-lg my-1 flex-1"
          >
            <input
              type="text"
              name="nome"
              placeholder="Nome"
              required
              value={formData.nome}
              onChange={handleChange}
              className="focus:outline-none max-h-full w-full p-2 text-neutral-600"
            />
          </label>
          <label
            htmlFor="email"
            className="caret-neutral-600 text-neutral-400 text-lg my-1 flex-1"
          >
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              required
              value={formData.email}
              onChange={handleChange}
              className="focus:outline-none max-h-full w-full p-2 text-neutral-600"
            />
          </label>
          <label
            htmlFor="whatsapp"
            className="caret-neutral-600 text-neutral-400 text-lg my-1 flex-1"
          >
            <input
              type="text"
              name="whatsapp"
              placeholder="Whatsapp (apenas numeros, com DDD)"
              required
              value={formData.whatsapp}
              onChange={handleChange}
              maxLength={15}
              className="focus:outline-none max-h-full w-full p-2 text-neutral-600"
            />
          </label>
          <div className="flex justify-start items-center max-md:flex-wrap">
            <label
              htmlFor="servico"
              className="text-white capitalize font-normal my-1 flex-1"
            >
              Serviço desejado
              <select
                id="servico"
                name="servico"
                required
                className="focus:outline-none text-neutral-400 ml-4 p-2"
                onChange={handleChange}
                value={formData.servico}
              >
                <option value="Office">Station Office</option>
                <option value="Membership">Station Pass</option>
                <option value="Caixa Postal">Station Postal</option>
              </select>
            </label>
            <div className="flex-1 my-1">
              <label
                htmlFor="quantpessoas"
                className="text-white capitalize font-normal flex-1"
              >
                <input
                  name="quantpessoas"
                  placeholder="Quantidade de pessoas"
                  required
                  value={formData.quantpessoas}
                  onChange={handleChange}
                  className="focus:outline-none p-2 text-neutral-600 w-full"
                />
              </label>
            </div>
          </div>
          <div className="flex justify-center max-md:flex-col-reverse items-center">
            <button
              type="reset"
              onClick={handleClose}
              className="bg-white rounded-2xl p-4 mt-4 md:my-10 mx-5 text-black max-sm:w-2/3 w-1/3 text-xl uppercase font-semibold hover:rounded-none transition-all ease-in"
            >
              Cancelar
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={!isFormValid} // Disable the button if the form is not valid
              className={`bg-white rounded-2xl p-4 mt-5 md:my-10 mx-5 text-black max-sm:w-2/3 w-1/3 text-xl uppercase font-semibold hover:rounded-none transition-all ease-in ${
                !isFormValid ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? "Enviando..." : "Enviar"}
            </button>
          </div>
        </form>
      </div>
    </dialog>
  );
}

export default PopupForm;
