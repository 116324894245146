async function fetchBlogPosts() {
  // Replace with your blog URL
  const blogUrl = 'https://stationwork.com.br/blog/wp-json/wp/v2/posts?_embed';

  // Use the Fetch API to make a GET request to the blog URL
  return fetch(blogUrl)
    .then((response) => {
      // Check if the response status is OK (status code 200)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // Parse the JSON data in the response
      return response.json();
    })
    .then((data) => {
      // The 'data' variable now contains the blog posts in JSON format
      // console.log(data);
      // You can process the data or display it as needed
      return data;
    })
    .catch((error) => {
      console.error('Error fetching blog posts:', error);
    });
}

export default fetchBlogPosts;
