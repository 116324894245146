import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Logo_Station.png";

function ErrorPage() {
  return (
    <div className="h-screen text-center flex flex-col items-center justify-center py-10">
      <img src={logo} alt="logo Station Work" className="w-1/2" />
      <h1 className="uppercase p-10 text-black text-center text-4xl font-semibold">
        página não encontrada!
      </h1>
      <Link
        to="/"
        className="bg-roxo p-2 text-white uppercase text-lg font-semibold hover:font-bold hover:p-3 transition-all ease-linear"
      >
        Home
      </Link>
    </div>
  );
}

export default ErrorPage;
