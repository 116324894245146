import React, { useEffect, useState } from "react";
import fetchBlogPosts from "../utils/fetchPosts";
import { Link } from "react-router-dom";
import Loader from "./Loader";

const BlogPosts = () => {
  const [posts, setPosts] = useState(null); // Initialize as null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBlogPosts();
        setPosts(data);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mt-20 md:mt-0 md:pt-20 pb-16 flex flex-col items-center justify-center text-roxo">
      {posts !== null && posts.length > 0 ? (
        <div
          id="posts-wrapper"
          className="mx-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 xl:mx-32"
        >
          {posts.map((post) => (
            <Link to={`/blog/posts/${post.id}`} key={post.id}>
              <div
                id="singlepost"
                className="my-8 pb-4 hover:-translate-y-2 hover:shadow-sm transition-all ease-linear"
              >
                <div
                  id="image-wrapper"
                  className="max-w-xs mb-4 h-40 max-h-40 overflow-clip"
                >
                  <img
                    src={post._embedded["wp:featuredmedia"][0].source_url}
                    alt={post._embedded["wp:featuredmedia"][0].title.rendered}
                    className="object-cover"
                  />
                </div>
                <div id="text-wrapper" className="max-w-xs px-3">
                  <h3 className="text-xl font-bold mb-2">
                    {post.title.rendered}
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${post.excerpt.rendered.slice(0, 100)}...`,
                    }}
                  />
                  <p className="flex font-bold">
                    Leia mais{" "}
                    <span className="text-verdeSeta ml-1 font-thin">❯</span>
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default BlogPosts;
