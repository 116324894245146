/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-undef */
import React, { useState } from "react";

import MapEmbed from "./MapEmbed";
import MapMobile from "./MapMobile";

import "./StylesMaps.css";

function Maps() {
  const screenSize = window.innerWidth;
  const [mapOpen, setMapOpen] = useState(false);

  return (
    <div className="App flex-auto">
      <section id="mapa" className="mx-auto uppercase pt-10">
        <div className="bg-roxo paralelograma3 w-80 h-16 mx-auto">
          <div className="flex justify-center items-center w-full h-full max-sm:ml-0 max-sm:mr-0 max-sm:my-0 text-center">
            <button
              className="text-white font-semibold text-center uppercase"
              onClick={() => setMapOpen(!mapOpen)}
            >
              Ver localização no mapa
            </button>
          </div>
        </div>
        <div
          className={`slide-container ${
            mapOpen ? "slide-down" : "slide-up"
          } text-center flex justify-center mt-10`}
        >
          {screenSize > 420 ? <MapEmbed /> : <MapMobile />}
        </div>
      </section>
    </div>
  );
}

export default Maps;
