import { Icon } from "@iconify/react";
import React, { useState } from "react";
import "../../styles/carroselPlanos.css";

import Foto1 from "../../../assets/FotoComentarios/Brenno.png";
import Foto2 from "../../../assets/FotoComentarios/Thais.png";
import Foto3 from "../../../assets/FotoComentarios/Klaus.png";
import Foto4 from "../../../assets/FotoComentarios/Leticia.png";
import Foto5 from "../../../assets/FotoComentarios/Roberto.png";

function Comentarios() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const plans = [
    {
      foto: Foto1,
      description:
        "Ótimo espaço pra trabalhar com espaço climatizado. Durante as pausas fazer um networking, apreciar o Marco Zero, a vista do mar azul e a rua mais bonita do mundo, tudo ao mesmo tempo!",
      nome: "Brenno Calado",
      empresa: "Freelancer",
    },
    {
      foto: Foto3,
      description: "Lugar massa e com pessoas incríveis!",
      nome: "Klaus Piaia",
      empresa: "Empresário",
    },
    {
      foto: Foto5,
      description:
        "Ótimo espaço, funcional, bem estruturado (Wi-Fi, banheiros incríveis, excelente área comum). Atendimento incrível e uma equipe de gestão pronta pra te ajudar se precisar de uma sala de reunião ou um espaço extra de urgência",
      nome: "Roberto Silva",
      empresa: "Kalea",
    },
    {
      foto: Foto2,
      description:
        "Lugar legal para realização de cursos e eventos. Fiz um evento aqui e tive todo o suporte da equipe, muito bom.",
      nome: "Thaís Rodrigues ",
      empresa: "Orientadora da Berlim Digital",
    },
    {
      foto: Foto4,
      description:
        "O melhor espaço de coworking do Recife, sem dúvida. Super bem localizado, na rua mais linda do mundo (invejosos dirão que é a terceira, porém pra mim é a primeira e pronto), e além de lindo, super confortável e instagramável, ainda dá pra comprar (lá dentro mesmo) várias bebidas e comidinhas... O pessoal que cuida do espaço tbm é sensacional!! Adoro esse lugar, melhor cantinho do Recife Antigo.",
      nome: "Leticia Andrade",
      empresa: "Desenvolvedora da BResults",
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === plans.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? plans.length - 1 : prevSlide - 1
    );
  };

  const currentPlan = plans[currentSlide];

  return (
    <div className="container-carrossel bg-black relative py-10">
      <div className="carousel text-roxoEscuro relative mx-8 ">
        <button
          className={`carousel__arrow carousel__arrow--prev absolute top-20 -left-9 cursor-pointer`}
          onClick={prevSlide}
        >
          <Icon
            icon="bytesize:chevron-left"
            width={60}
            height={60}
            color="white"
          />
        </button>
        <div
          className={`carousel__slide px-2 pb-3 flex flex-col justify-center items-center`}
        >
          <div className="overflow-clip w-52 h-52 rounded-full mb-2">
            <img
              className="object-cover w-52 h-52"
              src={currentPlan.foto}
              alt="Foto"
            />
          </div>
          <p className="mt-3 text-center text-white text-base/none mb-4">
            {currentPlan.description}
          </p>
          <div className="my-2 text-center">
            <p className="text-xl/tight text-white capitalize font-black my-2">
              {currentPlan.nome}
            </p>
            <p className="text-xl/tight text-white capitalize font-semibold">
              {currentPlan.empresa}
            </p>
          </div>
        </div>
        <button
          className={`carousel__arrow carousel__arrow--prev absolute top-20 -right-9 cursor-pointer`}
          onClick={nextSlide}
        >
          <Icon
            icon="bytesize:chevron-right"
            width={60}
            height={60}
            color="white"
          />
        </button>
      </div>
    </div>
  );
}

export default Comentarios;
